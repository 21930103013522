import { useState } from "react";

import { Button } from "@bit/healthmug.admin.button";

function ChangePasswordScreen() {
	const [old_password, setOldPassword] = useState("");
	const [new_password, setNewPassword] = useState("");
	const [confirm_password, setConfirmPassword] = useState("");
	const [err, setErr] = useState({});

	const onChangeEvent = ({ target }) => {
		if (target.name === "old_password") {
			setOldPassword(target.value);
		} else if (target.name === "new_password") {
			setNewPassword(target.value);
		} else {
			setConfirmPassword(target.value);
		}
	};

	const onFocusEvent = (key) => {
		const error = { ...err };
		if (!!error[key]) {
			delete error[key];
			setErr(error);
		}
	};

	const validate = () => {
		let someErrorState = false;
		const errors = {};

		if (old_password.length === 0) {
			errors.old_password = "Old Password field cannot be left empty";
			if (err.old_password !== "Old Password field cannot be left empty" && !someErrorState) someErrorState = true;
		}

		if (new_password.length === 0) {
			errors.new_password = "New Password field cannot be left empty";
			if (err.new_password !== "New Password field cannot be left empty" && !someErrorState) someErrorState = true;
		} else if (new_password.length < 6) {
			errors.new_password = "Password cannot be less than 6 characters";
			if (err.new_password !== "Password cannot be less than 6 characters" && !someErrorState) someErrorState = true;
		}

		if (confirm_password.length === 0) {
			errors.confirm_password = "Confirm Password field cannot be left empty";
			if (err.confirm_password !== "Confirm Password field cannot be left empty" && !someErrorState) someErrorState = true;
		} else if (confirm_password !== new_password) {
			errors.confirm_password = "Confirm Password should be the same as New Password";
			if (err.confirm_password !== "Confirm Password should be the same as New Password" && !someErrorState) someErrorState = true;
		}

		if (Object.keys(errors).length > 0) {
			if (someErrorState) {
				setErr(errors);
			}
		} else {
			if (Object.keys(err).length > 0) {
				setErr(errors);
			}
			return true;
		}
	};

	const handleEnter = (event) => {
		if (event.keyCode === 13) {
			validate();
		}
	};

	return (
		<div uk-height-viewport="true" className="uk-flex uk-flex-center uk-flex-middle">
			<div className="changePasswordWrap uk-width-1-4@l uk-width-1-3@m uk-width-2-3@s">
				<div className="pageTitle">
					<h2>Change Password</h2>
				</div>
				<div>
					<div>
						<div className="smallFont brandTextMediumGray">Old Password</div>
						<input
							onChange={onChangeEvent}
							onFocus={onFocusEvent}
							value={old_password}
							error={err.old_password}
							onKeyUp={handleEnter}
							type="password"
							autoComplete="off"
							name="old_password"
							className="uk-input uk-form-small"
							required
						/>
						<div className="errorLabel">{err.old_password}</div>
					</div>
					<div>
						<div className="smallFont brandTextMediumGray">New Password</div>
						<input
							onChange={onChangeEvent}
							onFocus={onFocusEvent}
							value={new_password}
							onKeyUp={handleEnter}
							autoComplete="off"
							type="password"
							name="new_password"
							className="uk-input uk-form-small"
							required
						/>
						<div className="errorLabel">{err.new_password}</div>
					</div>
					<div>
						<div className="smallFont brandTextMediumGray">Confirm Password</div>
						<input
							onChange={onChangeEvent}
							onFocus={onFocusEvent}
							value={confirm_password}
							onKeyUp={handleEnter}
							autoComplete="off"
							type="password"
							name="confirm_password"
							className="uk-input uk-form-small"
							required
						/>
						<div className="errorLabel">{err.confirm_password}</div>
					</div>

					<Button onClick={validate} className="uk-margin-small-top uk-width-1-1" primary>
						<span className="btnLoader">CHANGE PASSWORD</span>
					</Button>
				</div>
			</div>
		</div>
	);
}

export default ChangePasswordScreen;
