import { ON_CHANGE_USER_LOGIN, UPDATE_ERROR_STATE, DELETE_ERROR_ON_FOCUS, CHANGE_LOADER } from "./types.js";

const email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,3})$/;

export const OnChange = (key, value) => (dispatch) => {
	dispatch({ type: ON_CHANGE_USER_LOGIN, key, value });
};

export const SubmitUserLogin = () => (dispatch, getState) => {
	const { email } = getState();
	const { pwd } = getState();
	dispatch({ type: CHANGE_LOADER, loader: true });
	fetch(`${window.location.origin}/home/userlogin`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ email, pwd })
	})
		.then((response) => (response.ok ? response.text() : Promise.reject(response)))
		.then((data) => {
			if (data) {
				window.location = `${window.location.origin}/analytics/dashboard`;
			} else {
				alertify.error("Invalid Email or Password");
			}
		})
		.catch((error) => {
			dispatch({ type: CHANGE_LOADER, loader: false });
			if (error instanceof Error) {
				alertify.error("An Error has occurred");
			} else {
				error.text().then((data) => {
					alertify.error(data);
				});
			}
		});
};

export const LoginValidation = () => (dispatch, getState) => {
	const { email } = getState();
	const { pwd } = getState();
	const stateErrors = getState().errors;

	let someErrorState = false;
	const errors = {};

	if (!email || email === "") {
		errors.email = "Email is required";
		if (stateErrors.email !== errors.email) someErrorState = true;
	} else if (!email_validator.test(email)) {
		errors.email = "Invalid Email";
		if (stateErrors.email !== errors.email) someErrorState = true;
	}

	if (!pwd || pwd.trim() === "") {
		errors.pwd = "Password is required";
		if (stateErrors.pwd !== errors.pwd) someErrorState = true;
	}

	if (Object.keys(errors).length > 0) {
		if (someErrorState) {
			dispatch({ type: UPDATE_ERROR_STATE, errors });
			return false;
		}
	} else {
		if (Object.keys(stateErrors).length > 0) {
			dispatch({ type: UPDATE_ERROR_STATE, errors });
		}
		return true;
	}
};

export const OnFocus = (key) => (dispatch) => {
	dispatch({ type: DELETE_ERROR_ON_FOCUS, key });
};
