import { connect } from "react-redux";

import { Button } from "@bit/healthmug.admin.button";

import { OnChange, LoginValidation, SubmitUserLogin, OnFocus } from "~/actions/login-action";

function Login({ email, pwd, errors, loader, On_Change, Login_Validation, Submit_User_Login, On_Focus }) {
	return (
		<div className="loginWrap">
			<div className="loginInnerBox">
				<div className="uk-text-center">
					<img src="/images/logo.png" className="logo" alt="" />
				</div>
				<div className={`${errors.email ? "hasError" : ""}`}>
					<label htmlFor="email">
						Email Id
						<input
							type="email"
							id="email"
							name="email"
							onChange={(e) => On_Change(e.target.name, e.target.value)}
							value={email}
							autoComplete="off"
							placeholder="Enter your email..."
							onFocus={(e) => On_Focus(e.target.name)}
						/>
					</label>
					{errors.email && <div className="error">{errors.email}</div>}
				</div>
				<div className={`${errors.pwd ? "hasError" : ""}`}>
					<label htmlFor="pwd">
						Password
						<input
							type="password"
							name="pwd"
							id="pwd"
							onChange={(e) => On_Change(e.target.name, e.target.value)}
							placeholder="Enter password..."
							autoComplete="off"
							value={pwd}
							onFocus={(e) => On_Focus(e.target.name)}
							onKeyPress={(e) => e.key === "Enter" && Login_Validation() && Submit_User_Login()}
						/>
					</label>
					{errors.pwd && <div className="error">{errors.pwd}</div>}
				</div>
				<Button onClick={() => Login_Validation() && Submit_User_Login()} loader={loader} className="uk-button uk-button-secondary uk-width-1-1">
					Login
				</Button>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	email: state.email,
	pwd: state.pwd,
	loader: state.loader,
	errors: state.errors
});
const mapDispatchToProps = (dispatch) => ({
	On_Change: (key, value) => dispatch(OnChange(key, value)),
	On_Focus: (key) => dispatch(OnFocus(key)),
	Login_Validation: () => dispatch(LoginValidation()),
	Submit_User_Login: () => dispatch(SubmitUserLogin())
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
