import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

import Login from "~/screens/home/login-screen";
import ChangePasswordScreen from "~/screens/home/change-password-screen";
import LoginReducer from "~/reducers/login-reducer";

import "~/screens/styles/general.scss";
import "~/screens/styles/uikit-custom.scss";

UIkit.use(Icons);

const store = createStore(LoginReducer, applyMiddleware(thunk));
function App() {
	function pageType() {
		const page = window.location.pathname;
		if (page === "/") return <Login />;
		if (page === "/change-password") return <ChangePasswordScreen />;
	}

	return <Provider store={store}>{pageType()}</Provider>;
}

ReactDOM.render(<App />, document.getElementById("pageRoot"));
