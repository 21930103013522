import { ON_CHANGE_USER_LOGIN, UPDATE_ERROR_STATE, DELETE_ERROR_ON_FOCUS, CHANGE_LOADER } from "~/actions/types.js";

const __INITIAL_STATE__ = {
	email: "",
	pwd: "",
	loader: false,
	errors: {}
};

export default (state = __INITIAL_STATE__, action) => {
	const newState = { ...state };

	if (action.type === ON_CHANGE_USER_LOGIN) {
		newState[action.key] = action.value;
		return newState;
	}

	if (action.type === UPDATE_ERROR_STATE) {
		newState.errors = action.errors;
		return newState;
	}

	if (action.type === DELETE_ERROR_ON_FOCUS) {
		if (!!newState.errors[action.key]) {
			const error = { ...newState.errors };
			delete error[action.key];
			newState.errors = error;
		}
		return newState;
	}

	if (action.type === CHANGE_LOADER) {
		newState.loader = action.loader;
		return newState;
	}

	return state;
};
